import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pathname, image }) {
    const { site } = useStaticQuery(
        graphql `
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        url
                        facebookAppId
                    }
                }
            }
    `);

    const metaDescription = description || site.siteMetadata.description;
    const imageData = (!image ? {
        src: `${site.siteMetadata.url}/icons/icon-512x512.png`,
        width: 512,
        heigh: 512
    } : {
        src: image,
        width: 750,
        heigh: 750
    });

    return <Helmet
    htmlAttributes = {
        {
            lang
        }
    }
    title = { title }
    titleTemplate = { `%s | ${site.siteMetadata.title}` }
    link = {
        [{
            rel: `canonical`,
            href: `${site.siteMetadata.url}${pathname}`
        }]
    }
    meta = {
        [{
                name: `description`,
                content: metaDescription
            },
            //https://developers.facebook.com/docs/sharing/webmasters#markup
            {
                property: `og:site_name`,
                content: site.siteMetadata.title
            },
            {
                property: `og:url`,
                content: `${site.siteMetadata.url}${pathname}`
            },
            {
                property: `og:locale`,
                content: lang
            },
            {
                property: `og:title`,
                content: title
            },
            {
                property: `og:description`,
                content: metaDescription
            },
            {
                property: `og:type`,
                content: `website`
            },
            {
                property: `og:image`,
                content: imageData.src
            },
            {
                property: `og:image:width`,
                content: imageData.width
            },
            {
                property: `og:image:height`,
                conent: imageData.heigh
            },
            {
                property: `fb:app_id`,
                content: site.siteMetadata.facebookAppId
            },
            {
                name: `twitter:card`,
                content: `summary_large_image`,
            },
            {
                name: `twitter:site`,
                content: site.siteMetadata.title,
            },
            {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
            },
            {
                name: `twitter:title`,
                content: title,
            },
            {
                name: `twitter:description`,
                content: metaDescription,
            }
        ].concat(meta)
    }
    />
}

SEO.defaultProps = {
    lang: `es`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    url: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO